import React, {Component} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import './calStyle.css'

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleEventNOTClick = this.handleEventNOTClick.bind(this);
    this.state = {eventIsClicked: false};
  }
  handleEventClick() {
    this.setState({eventIsClicked: true});
  }

  handleEventNOTClick() {
    this.setState({eventIsClicked: false});
  }
  state = {
    weekendsVisible: true,
    currentEvents: [],
    clicked: false,
    clickedTitle: '',
    clickedStartDate: '',
    clickedTime: '',
    clickedEvent: []
  }
  render() {
    if(this.state.clicked){
    return (
      <div className='demo-app'>
        <div className="top">
          {this.renderEventBar()}
        </div>
        <div className='bottom-calendar-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, googleCalendarPlugin]}
            googleCalendarApiKey='AIzaSyDiELiu3A2AKFICy8JWJE6vWhs9Qg-lsms'
            events= {{
              googleCalendarId: 'uclasoles@gmail.com'
            }}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,listYear'
            }}
            weekends={this.state.weekendsVisible}
            displayEventTime={false}
            initialView='dayGridMonth'
            eventClick={this.handleEventClick}
          />
        </div>
      </div>
    )
    }
    else{
      return (
        <div className='demo-app'>
          <div className='bottom-calendar-main' style={{width: '100%'}}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, googleCalendarPlugin]}
              googleCalendarApiKey='AIzaSyDiELiu3A2AKFICy8JWJE6vWhs9Qg-lsms'
              events= {{
                googleCalendarId: 'uclasoles@gmail.com'
              }}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listYear'
              }}
              weekends={this.state.weekendsVisible}
              displayEventTime={false}
              initialView='dayGridMonth'
              eventClick={this.handleEventClick}
            />
          </div>
        </div>
      )
    }
  }

  renderEventBar()
  {
    return(
      <div className='cal-event-bar'>
        <div style={{}}>
          <div style={{textAlign: 'center'}}>Event Info</div>
          <div className='cal-event-section'>
            <div style={{backgroundColor: 'white', color: 'black', borderBottomRightRadius: '17px', borderBottomLeftRadius: '17px'}}>
              <ul>
                <div className="titleEventClicked"><b>{this.state.clickedTitle}</b><br/></div>
                <br/>
                <div className="otherInfo">
                <i>{this.state.clickedStartDate}</i>
                <div style={{color: 'gray'}}><i>{this.state.clickedTime}</i></div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
  handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault(); //doesn't take you to google calendar

    this.setState({
      clickedEvent: clickInfo,
    });

    var time="";
    var sD = new Date(this.state.clickedEvent['event']['startStr']);
    var eD = new Date(this.state.clickedEvent['event']['endStr']);
    if(this.state.clickedEvent['event']['allDay']){
      sD.setDate(sD.getDate()+1);  //if all day event, we do not care about the autogenerated times and fix day
                                   //ex. google calendar automatically does April 14 5:00pm to April 15 5:00pm for a 1 day allday event
    }
    else{
      var startTime = sD.toLocaleTimeString().replace(":00 ", " "); //remove the seconds from time
      var endTime = eD.toLocaleTimeString().replace(":00 ", " ");
      time = startTime + " - " + endTime;
    }

    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    var startDate = sD.toLocaleDateString('en-us', options)
    var endDate = eD.toLocaleDateString('en-us', options)
    
    var date;
    if(startDate === endDate) 
      date = startDate;
    else{
      date = startDate + " to " + endDate;
    }
    
    this.setState({ //updates the state of the title, data, and if the event was clicked
      clickedTitle: this.state.clickedEvent['event']['_def']['title'],
      clickedStartDate: date,
      clickedTime: time,
      clicked: true,
    });
  }
}


export default Calendar;